/* SIDE NAVIGATION */
.rs-sidenav-default{
  height: 100vh;
  overflow: hidden;
}
.sidenav-header {
  height: 100px;
}
.sidenav-header .header-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.header-container p {
  font-size: 16px;
  font-weight: bold;
  color: blue;
  text-align: center;
}

/* DASHBOARD */
.admin-dashboard-wrapper {
  padding: 100px;
  border-radius: 10px;
  box-shadow: 0 0 2px #efefef;
  background-color: #efefef;
  height: 90vh;
  margin: 10px;
}

.dashboard-welcome-card {
  padding: 20px;
  background-color: #fff;
  margin: 20px;
  border-radius: 8px;
}
.dashboard-welcome-card h4 {
  font-size: 20px;
  text-align: center;
}

.dashboard-logo {
  padding: 5px;
  margin: 10px 20px;
  background-color: #fff;
  border-radius: 8px;
}
.dashboard-logo h5 {
  padding: 10px;
}
.dashboard-logo .dashboard-logo-img-container {
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.dashboard-logo img {
  height: 50px;
  object-fit: contain;
}
.dashboard-logo-img-container label {
  margin: 20px;
  font-size: 20px;
  color: #0e71c2;
  text-decoration: underline;
  cursor: pointer;
}
.dashboard-change-logo-wrapper .logo-preview {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}
.dashboard-change-logo-wrapper .logo-preview img {
  height: 50px;
  object-fit: contain;
  margin-bottom: 5px;
}
.dashboard-change-logo-wrapper .logo-preview button {
  padding: 5px 10px;
  border-radius: 6px;
}
/* TEAM */
.team-member-wrapper {
  padding: 10px;
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  box-shadow: 0 0 2px #efefef;
  margin: 10px;
}
.team-member-wrapper img {
  width: 200px;
  height: 100%;
  object-fit: cover;
  padding: 10px;
}
.team-member-wrapper .info {
  padding: 10px;
  flex: 1;
}
.team-member-wrapper .info h5 {
  font-size: 18px;
  margin: 6px;
}
.team-member-wrapper .info p.position {
  margin: 6px;
  font-weight: bold;
}
.team-member-wrapper .info p.description {
  margin: 5px;
  font-size: 15px;
}
/* END */
/* SERVICES */
.admin-service-wrapper {
  padding: 0;
  box-shadow: 0 0 2px #efefef;
  margin: 5px;
}
.admin-service-wrapper img {
  width: 100%;
  height: 160px;
  object-fit: contain;
}
.admin-service-wrapper .info {
  padding: 10px;
}
.admin-service-wrapper .info h5 {
  font-size: 17px;
  margin: 6px;
}
.admin-service-wrapper .info p {
  margin: 5px;
  font-size: 15px;
}
/* END */
.edit-buttons {
  padding: 10px;
  text-align: center;
}
.edit-buttons button {
  padding: 6px 15px;
  margin: 10px;
  border: none;
  box-shadow: 0 0 2px #efefef;
  border-radius: 4px;
  color: #fff;
}
.edit-buttons button.edit {
  background-color: goldenrod;
}
.edit-buttons button.delete {
  background-color: red;
}
.edit-buttons button:hover {
  box-shadow: none;
}
.top-header {
  padding: 20px;
  background-color: #efefef;
  border-bottom: 1px solid #999;
}
.top-header h5 {
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
}
.create-section {
  padding: 20px;
  text-align: end;
  background-color: #efefef;
  border-bottom: 1px solid #999;
}
.create-section button {
  padding: 8px 20px;
  font-size: 15px;
  border-radius: 4px;
  background-color: cadetblue;
  color: #fff;
  box-shadow: 0 0 2px #999;
}
.create-section button:hover {
  box-shadow: none;
}
.form-create {
  display: flex;
}
.form-create .image-uploader, .form-create .image-uploader label{
  width: 150px;
  height: 150px;
}
.form-create .image-uploader label svg {
  height: 40px;
  width: 40px;
}
.form-create .image-uploader label img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.form-create form {
  flex: 1;
}
