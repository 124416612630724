/* NAVBAR */
.rs-navbar-default {
  height: 70px;
  background-color: #fff !important;
  box-shadow: 0 0 2px #efefef;
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
}
.rs-navbar-brand {
  padding: 5px !important;
}
.rs-nav-brand .open-menu {
  display: none;
}
.rs-navbar-brand img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.rs-navbar-nav.rs-navbar-right {
  float: right;
  height: -webkit-fill-available;
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.rs-navbar-default .rs-navbar-item.rs-navbar-item-active {
  background-color: blue !important;
  color: #fff !important;
  font-weight: bold;
}
.rs-navbar-nav.rs-navbar-right a {
  height: -webkit-fill-available;
  font-size: 16px;
}
/* CAROUSEL */
.carousel-wrapper {
  margin-top: 70px;
}
.rs-carousel {
  background-color: #fff !important;
  height: 500px !important;
}
.rs-carousel-slider-after, .rs-carousel-slider-item {
  background-color: #fff !important;
  position: relative;
}
.carousel-info img {
  object-fit: cover;
  width: 100%;
  height: 50vh;
}
.carousel-info div.description {
  position: absolute;
  bottom: 0;
  background-color: #000;
  min-height: 120px;
  width: 50%;
  z-index: 10;
}
.carousel-info div.description p {
  color: #fff;
  font-size: 20px;
  padding: 50px;
  text-align: center;
}
/* WHO WE ARE */
.who-we-are {
  margin-top: 50px;
  padding: 20px;
}
.who-we-are h5.title {
  font-size: 30px;
  letter-spacing: -.2px;
  margin-bottom: 30px;
  text-align: center;
  color: blue;
}
.who-we-are p.introduction {
  width: 70%;
  margin: auto;
  text-align: justify;
  font-size: 16px;
}
.who-we-are-container {
  position: relative;
  padding: 20px;
}
.who-we-are-container img {
  width: 100%;
  object-fit: contain;
}
.who-we-are-container p.introduction {
  width: 50%;
  text-align: justify;
  font-size: 16px;
  background-color: yellow;
  padding: 40px;
  position: absolute;
  box-shadow: 0 0 2px #efefef;
  border-radius: 2px;
  top: 0%;
  left: 0%;
  color: #000;
}
.who-we-are-container .vision-mission {
  position: absolute;
  right: 0;
  width: 40%;
  bottom: 20%;
}
.vision-mission div {
  padding: 20px;
  background-color: yellow;
  border-radius: 2px;
  margin: 10px 2px;
  color: #000;
}
/* .vision-mission div h6 {

} */
/* ABOUT US */

/* SERVICES */
.services-wrapper {
  padding: 50px;
}
.services-wrapper h5.title {
  font-size: 30px;
  letter-spacing: -.2px;
  margin-bottom: 30px;
  text-align: center;
  color: blue;
}
.services-wrapper .services-container {
  width: 95%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}
.services-container .service {
  width: 250px;
  box-shadow: 0 0 2px #efefef;
  border-radius: 2px;
  margin: 5px;
}
.services-container .service img {
  width: 100%;
  object-fit: cover;
  height: 160px;
  border-radius: 2px 2px 0 0;
}
.services-container .service .info {
  padding: 10px;
}
.services-container .service .info h6 {
  font-size: 15px;
  font-weight: bold;
  margin: 5px;
}
.services-container .service .info p {
  font-size: 15px;
  margin: 5px;
}
.services-container .service .description {
  padding: 10px;
}
/* TEAM */
.team-wrapper {
  padding: 50px;
}
.team-wrapper h5.title {
  font-size: 30px;
  letter-spacing: -.2px;
  margin-bottom: 30px;
  text-align: center;
  color: blue;
}
.team-wrapper .team-container {
  padding: 5px;
}
.team-wrapper .team-container .member {
  width: 70%;
  border-radius: 4px;
  box-shadow: 0 0 4px #efefef;
  padding: 10px;
  display: flex;
  margin: 10px auto;
}
.team-container .member .img-info {
  flex: 1;
}
.team-container .member .img-info img {
  height: 120px;
  width: 100%;
  object-fit: contain;
}
.team-container .member .img-info h6 {
  margin: 6px;
  font-size: 14px;
  text-align: center;
}
.team-container .member .img-info p {
  font-size: 14px;
  margin: 5px;
  font-style: oblique;
}
.team-container .member .description {
  padding: 10px;
  flex: 2;
}
/* GALLERY */
.gallery-wrapper {
  padding: 20px;
  margin-top: 50px;
}
.gallery-wrapper h5.title {
  font-size: 30px;
  letter-spacing: -.2px;
  margin-bottom: 30px;
  text-align: center;
  color: blue;
}
.gallery-wrapper .gallery-container {
  width: 95%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}
.gallery-container .img-container {
  width: 200px;
  margin: 7px;
  border-radius: 5px;
  position: relative;
}
.gallery-container img {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.gallery-container .img-container .caption {
  position: relative;
  color: #fff;
  padding: 6px;
  font-size: 14px;
  font-style: oblique;
  color: #333;
}
/* FOOTER */
.footer{
  background-color: #fff;
}
.footer .contacts {
  display: flex;
  padding: 50px;
  background-color: #efefef;
}
.footer .contacts div {
  flex: 1;
}
.footer .contacts .info {
  padding: 5px;
}
.footer .contacts .info img {
  height: 50px;
  object-fit: contain;
  width: 80%;
}
.footer .contacts .info .title {
  font-size: 15px;
  font-weight: bold;
  margin: 6px;
}
.footer .contacts .info .moto {
  font-size: 14px;
  color: #555;
  font-style: oblique;
}
.footer .contacts .navigator {
  padding: 5px;
}
.footer .contacts .contact-info .head,
.footer .contacts .navigator .head {
  margin: 5px;
  font-weight: bold;
  text-align: center;
  font-size: 15px;
}
.footer .contacts .contact-info ul,
.footer .contacts .navigator ul {
  list-style: none;
}
.footer .contacts .contact-info ul li,
.footer .contacts .navigator ul li {
  padding: 6px;
  cursor: pointer;
  text-align: center;
}
.footer .contacts .contact-info ul li a,
.footer .contacts .navigator ul li a {
  color: #333;
  text-decoration: none;
}
.footer .contacts .contact-info {
  padding: 5px;
}
.footer .copywrite {
  background-color: black;
  padding: 20px;
  display: flex;
}
.footer .copywrite div{
  flex: 1;
}
.footer .copywrite .owner {
  font-weight: bold;
  font-size: 15px;
  color: #fff;
}
.footer .copywrite .creator {
  color: #fff;
  text-align: end;
  font-size: 15px;
}
.footer .copywrite .creator a {
  color: #fff;
  text-decoration: none;
  font-size: 15px;
  font-weight: bold;
  text-align: end;
}
/* LOGIN */
.login {
  position: absolute;
  top: 25%;
  width: 100%;
}
.login-container {
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 5px;
}
.login-container h3 {
  padding: 20px;
}
.login-container .rs-btn-default.rs-btn-green {
  width: 150px;
  font-weight: bold;
}

/* RESPONSIVE SECTION */
@media only screen and (max-width: 425px) {
  .rs-navbar-brand {
    display: flex !important;
    width: 100%;
    height: 70px !important;
  }
  .rs-navbar-brand .open-menu {
    display: block;
    width: 70px;
  }
  .rs-navbar-nav.rs-navbar-right {
    display: none;
  }
  .carousel-info div.description {
    min-height: 70px;
    width: 100%;
    z-index: 10;
  }
  .carousel-info div.description p {
    font-size: 14px;
    padding: 20px;
  }
  .who-we-are {
    padding: 5px;
  }
  .who-we-are h5.title {
    margin-bottom: 20px;
  }
  .who-we-are-container {
    background-image: url(../assets/what_we_do_background.jpg);
    background-repeat: no-repeat;
    background-position: inherit;
    background-attachment: fixed;
  }
  .who-we-are-container p.introduction, .who-we-are-container .vision-mission {
    position: relative;
    width: 100%;
  }
  .who-we-are-container img {
    display: none;
  }
  .services-wrapper {
    padding: 10px;
  }
  .services-wrapper .services-container {
    width: 100%;
  }
  .services-container .service {
    width: 100%;
  }
  .team-wrapper {
    padding: 10px;
  }
  .team-wrapper .team-container .member {
    width: 100%;
    flex-wrap: wrap;
    display: block;
    padding: 0;
  }
  .team-container .member .img-info {
    display: flex;
  }
  .team-container .member .img-info img {
    height: 150px;
    width: 150px;
  }
  .team-container .member .img-info .member-info {
    flex: 1;
  }
  .gallery-wrapper {
    padding: 5px;
    margin-top: 20px;
  }
  .gallery-wrapper .gallery-container {
    width: 100%;
  }
  .gallery-container .img-container {
    width: 100%;
  }
  .footer .contacts {
    padding: 10px;
    display: block;
  }
  .footer .contacts .info p {
    text-align: end;
    margin: 6px;
  }
  .footer .contacts .navigator {
    display: none;
  }
  .footer .copywrite {
    display: block;
  }
  .footer .copywrite .creator {
    margin-top: 10px;
  }
}
